import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Select, MenuItem, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../../AuthContext';

export default function AdmissionGraph() {
    const [data, setData] = useState(null);
    const [timeRange, setTimeRange] = useState('allTime');
    const [chartData, setChartData] = useState([]);
    const [loading ,setLoading] = useState(true)
    const { BaseURL } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BaseURL}/fetch/admissions`);
                setData(response.data);
                setLoading(true)
                setChartData(response.data.allTime || []); 
            } catch (error) {
                console.error('Error fetching admissions data:', error);
            }finally{
                setLoading(false);
            }
        };

        fetchData();
    }, [BaseURL]);

    useEffect(() => {
        if (data) {
            setChartData(data[timeRange] || []);
        }
    }, [timeRange, data]);

    return (
        <>
            <section className="flex flex-wrap relative shadow-md p-6">
                {
                    loading && <div className="z-50" style={{position:"absolute" ,display:"flex", alignItems:"center", justifyContent:"center" ,background:"#fff" ,height:"100%" , width:"100%"}} >
                        <CircularProgress/>
                    </div>
                }
                <div className="w-full mb-6">
                    <h3 className="font-extrabold text-2xl mb-4">Admissions Summary</h3>
                    <Select
                        value={timeRange}
                        onChange={(e) => setTimeRange(e.target.value)}
                        displayEmpty
                    >
                        <MenuItem value="allTime">All Time</MenuItem>
                        <MenuItem value="lastMonth">Last Month</MenuItem>
                        <MenuItem value="last90Days">Last 90 Days</MenuItem>
                    </Select>
                </div>

                {/* Admissions Graph */}
                <div className="w-full">
                    <ResponsiveContainer  height={300}>
                        <LineChart data={chartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </section>
        </>
    )
}
