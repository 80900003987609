import React, { useState, useEffect } from 'react';
import AdmissionGraph from '../../components/Admin/AdmissionGraph';
import Form from '../../components/Admin/Form';
import CalendarView from '../../components/Admin/CalenderView';
import StudentList from '../../components/Admin/StudentList';
import DashboardGrid from '../../components/Admin/DashboardGrid';

export default function Dashboard() {
    
    return (
        <div className="lg:max-w-full max-w-lg">
            <div className='flex justify-between' >
            <DashboardGrid/>
            </div>
            <AdmissionGraph/>
            <section className='w-full p-6 shadow-md  my-5 flex gap-6  lg:flex-row flex-col'>
                <Form  />
                <CalendarView  />
            </section>
            <section className='shadow-mg py-6 px-3' >
                <StudentList/>
            </section>
        </div>
    );
}

