import React, { useEffect, useState } from 'react'
import { useAuth } from '../../AuthContext'
import axios from 'axios';
import { Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';


export default function StudentList() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));


    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const { BaseURL } = useAuth();
    const [studentList, setStudentList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${BaseURL}/students/get`);
                setStudentList(response.data.students); // Update student list
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false); // Set loading to false once data is fetched
            }
        };

        fetchStudents();
    }, [BaseURL]);
    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>TGCI ID</StyledTableCell>
                            <StyledTableCell align="left">Name</StyledTableCell>
                            <StyledTableCell align="left">Phone</StyledTableCell>
                            <StyledTableCell align="left">Email</StyledTableCell>
                            <StyledTableCell align="left">Batch</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {studentList.slice(0, 6).map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row"></StyledTableCell>
                                <StyledTableCell align="left">{row.username}</StyledTableCell>
                                <StyledTableCell align="left">{row.phoneNumber}</StyledTableCell>
                                <StyledTableCell align="left">{row.email}</StyledTableCell>
                                <StyledTableCell align="left">{row.batch}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                        
                    </TableBody>
                </Table>
            </TableContainer>
            <div className='p-3' >
                <a className='cursor-pointer' href='student/view' >View & Edit Students</a>
            </div>
        </div>
    )
}
