import axios from 'axios'
import React, { Suspense , lazy } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../AuthContext';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Admin/Header';
import { FaHome, FaGraduationCap, FaBook, FaUser, FaAccessibleIcon } from 'react-icons/fa'; // Example icons
import { CircularProgress } from '@mui/material';
import { Summarize } from '@mui/icons-material';
export default function AdminBoard({wrappedComponents}) {

    const Dashboard = lazy(() => import('./Dashboard'));
    const { logout } = useAuth();
    const items = [
        { label: 'Dashboard', path: '/Admin', icon: <FaHome /> },
        {
            label: 'Exam',
            icon: <FaGraduationCap />,
            subItems: [
                { label: 'Results', path: '/exam/results' },
                { label: 'Create New Exam', path: '/exam/create' },
            ],
        },
        { label: 'Courses', path: '/courses', icon: <FaBook /> },
        { divider: true },
        {
            label: 'Students', icon: <FaUser />,
            subItems: [
                { label: 'Add Student', path: '/student/add' },
                { label: 'View Students', path: '/student/view' }
            ]
        },
        {
            label:"Attendance" , path:'/attendance' , icon:<FaAccessibleIcon/>
        }
    ];




    return (
        <>
            <Header />
            <div className="flex">
                <Sidebar items={items} />
                <Suspense fallback={<div className='flex-1 flex justify-center items-center h-[50vh]' ><CircularProgress/></div>} >
                    {wrappedComponents}
                </Suspense>
            </div>
        </>
    )
}
