import React from 'react';

const DashboardGrid = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 p-6">
      {/* Attendance Summary */}
      <div className="bg-sky-500 text-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300 transform hover:scale-105">
        <h2 className="text-2xl font-semibold mb-4 text-center">Attendance Summary</h2>
        <p className="text-white text-center">View overall attendance data.</p>
      </div>

      {/* Exams */}
      <div className="bg-gradient-to-r from-purple-500 to-pink-500 text-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300 transform hover:scale-105">
        <h2 className="text-2xl font-semibold mb-4 text-center">Exams</h2>
        <p className="text-white text-center">Check exam schedules and results.</p>
      </div>

      {/* Attendance */}
      <div className="bg-gradient-to-r from-green-400 to-blue-500 text-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300 transform hover:scale-105">
        <h2 className="text-2xl font-semibold mb-4 text-center">Attendance</h2>
        <p className="text-white text-center">Track daily attendance records.</p>
      </div>

      {/* Add Student */}
      <div className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300 transform hover:scale-105">
        <h2 className="text-2xl font-semibold mb-4 text-center">Add Student</h2>
        <p className="text-white text-center">Add a new student to the system.</p>
      </div>
    </div>
  );
};

export default DashboardGrid;
