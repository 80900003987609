import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import { Toaster } from 'react-hot-toast';
import ProtectRoute from './auth/ProtectRoute';
import AdminBoard from './pages/Admin/AdminBoard';
import Dashboard from './pages/Admin/Dashboard';

const Login = lazy(() => import('./pages/Login'))

export default function App() {
  return (
    <BrowserRouter>
      <Toaster position="top-center" reverseOrder={false} />
      <AuthProvider>
        <Routes>
          <Route element={<Protected />} >
            <Route path='/' element={<Login />} />
          </Route>
          <Route element={<ProtectRoute requiredRole="Admin" />}>
            <Route path="/Admin" element={<AdminBoard wrappedComponents={<Dashboard/>} ></AdminBoard>} />
            <Route path="/courses" element={<AdminBoard wrappedComponents={<>Courses</>} ></AdminBoard>} />
          </Route>
          <Route element={<ProtectRoute requiredRole="Student" />}>
            <Route path="/Student" element={<></>} />
          </Route>
          <Route element={<ProtectRoute requiredRole="Faculty" />}>
            <Route path="/Faculty" element={<></>} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}


const Protected = () => {
  const { user } = useAuth();
  if (user) {
    return <Navigate to={`${user.role}`} />
  }
  return <Outlet />
}
