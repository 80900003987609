import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Collapse, Divider, IconButton, useMediaQuery } from '@mui/material';
import { ExpandLess, ExpandMore, ChevronLeft, ChevronRight, Menu } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 270;

export default function Sidebar({ items, children }) {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detect small screens

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleSubMenuToggle = (index) => {
        setActiveSubMenu(activeSubMenu === index ? null : index);
    };

    return (
        <>
            {/* Hamburger Icon for Small Screens */}
            {isSmallScreen && (
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    edge="start"
                    className='fixed left-28  h-[20px] w-[20px] top-[-47px]'
                >
                    <Menu />
                </IconButton>
            )}

            {/* Sidebar Drawer */}
            <Drawer
                variant={isSmallScreen ? 'temporary' : 'permanent'} // Temporary for small screens, permanent otherwise
                open={open}
                onClose={handleDrawerToggle}
                sx={{
                    width: open && !isSmallScreen ? drawerWidth : 50,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: open && !isSmallScreen ? drawerWidth : 50,
                        boxSizing: 'border-box',
                        marginTop: isSmallScreen ? 0 : '82px',
                    },
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <div>
                    {!isSmallScreen && (
                        <IconButton onClick={handleDrawerToggle} sx={{ margin: 1 }}>
                            {open ? <ChevronLeft /> : <ChevronRight />}
                        </IconButton>
                    )}
                </div>

                {/* Sidebar Menu Items */}
                <List>
                    {items.map((item, index) => (
                        <div key={index}>
                            <ListItem
                                // button
                                onClick={() => item.subItems ? handleSubMenuToggle(index) : null}
                                component={Link}
                                to={item.path || ''}
                                selected={location.pathname === item.path}
                            >
                                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                <ListItemText primary={item.label} />
                                {item.subItems && (
                                    activeSubMenu === index ? <ExpandLess /> : <ExpandMore />
                                )}
                            </ListItem>

                            {/* Submenu Items */}
                            {item.subItems && (
                                <Collapse in={activeSubMenu === index} timeout="auto" unmountOnExit>
                                    <List>
                                        {item.subItems.map((subItem, subIndex) => (
                                            <ListItem
                                                key={subIndex}
                                                button
                                                component={Link}
                                                to={subItem.path}
                                                selected={location.pathname === subItem.path}
                                            >
                                                <ListItemIcon>-</ListItemIcon>
                                                <ListItemText primary={subItem.label} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            )}

                            {item.divider && <Divider />}
                        </div>
                    ))}
                </List>
            </Drawer>
        </>
    );
}
