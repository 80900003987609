import React from 'react'
import { Avatar, Divider, IconButton, Menu, MenuItem } from '@mui/material'
import { Logout, MenuRounded } from '@mui/icons-material'
import logo from '../../assets/tgci.png'
import { useAuth } from '../../AuthContext'
export default function Header() {
    const setings = ['Profile', 'Logout', 'TGCI Panel']
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const {logout} = useAuth();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}`,
        };
    }

    const { user } = useAuth();
    return (
        <div className='relative h-[81px] z-40 bg-white'>
            <nav className='flex px-3 bg-white py-2 items-center justify-between fixed w-full shadow-xl'>
                <div className='flex gap-3 items-center' >
                    <img src={logo} className='h-[65px] w-[65px]' alt="" />
                </div>
                <div>
                    <IconButton onClick={handleClick} >
                        {console.log(user)}
                        <Avatar {...stringAvatar(`${user.username}`)} />
                    </IconButton>
                    <IconButton onClick={logout} >
                        <Logout />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                        <MenuItem onClick={handleClose}>Logout</MenuItem>
                    </Menu>
                </div>
            </nav>
            <Divider />
        </div>
    )
}
