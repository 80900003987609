import React, { useState } from 'react';
import { TextField, Button, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../../AuthContext';
import toast from 'react-hot-toast';

export default function Form({ onEventAdded }) {
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const { BaseURL } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newEvent = { name, date, description, type };

        try {
            await axios.post(`${BaseURL}/calendar/add-event`, newEvent);
            toast.success("Event Added Successfully")
        } catch (error) {
            toast.error("Failed to add event")
            console.error('Error adding event:', error);
        }
    };

    return (
        <form  onSubmit={handleSubmit} className="lg:max-w-[500px] flex-1 lg:min-w-[300px]  gap-3 flex flex-col">
            <TextField
            variant='standard'
                label="Event Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="mb-4"
            />
            <TextField
             variant='standard'
                label="Date"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                required
                className="mb-4"
            />
            <TextField
             variant='standard'
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mb-4"
            />
            <Select
             variant='standard'
                value={type}
                onChange={(e) => setType(e.target.value)}
                displayEmpty
                required
                className="mb-4"
            >
                <MenuItem value="" disabled>Select Event Type</MenuItem>
                <MenuItem value="holiday">Holiday</MenuItem>
                <MenuItem value="exam">Exam</MenuItem>
                <MenuItem value="miscellaneous">Miscellaneous</MenuItem>
            </Select>
            <Button type="submit" variant="contained" color="primary">
                Add Event
            </Button>
        </form>
    );
}
